/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia'
import { DirectusModule } from '~/types/enums'
import type { Footer, Header, HeaderFooter } from '~/types/types'

export const useHeaderFooterStore = defineStore('header-footer-store', () => {
  const headerFooter = ref<HeaderFooter>({
    header: null,
    footer: null,
  })

  async function getHeaderFooterData() {
    if (headerFooter.value.header !== null && headerFooter.value.footer !== null) return
    try {
      const { $directus, $readItems } = useNuxtApp()

      const { data: headerData } = await useAsyncData(DirectusModule.Header, () => {
        return $directus.request($readItems(DirectusModule.Header as any, {}))
      })

      const { data: footerData } = await useAsyncData(DirectusModule.Footer, () => {
        return $directus.request($readItems(DirectusModule.Footer as any, {}))
      })
      if (headerData.value && footerData.value) {
        headerFooter.value = {
          header: headerData.value as unknown as Header,
          footer: footerData.value as unknown as Footer,
        }
      }
    } catch (error) {
      console.log('Error fetching header and footer data', error)
      // no need to throw error, just log it
    }
  }
  return {
    headerFooter,
    getHeaderFooterData,
  }
})
